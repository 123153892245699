const RECLASSIFICATION_GRADE_KEY = 'reclassification_grade'
const EX_FINAL_GRADE_KEY = 'ex_final_grade'

const getHeadCells = ({ shouldShowExFinal, showReclassification }) => [
  {
    id: 'index',
    numeric: false,
    disablePadding: true,
    label: '#'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Aluno'
  },
  {
    id: 'register',
    numeric: false,
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'assessment_grade',
    numeric: false,
    disablePadding: false,
    label: 'Avaliações Presenciais'
  },
  {
    id: 'activities_grade',
    numeric: false,
    disablePadding: false,
    label: 'Atividades Online'
  },
  {
    id: 'recovery_grade',
    numeric: false,
    disablePadding: false,
    label: 'Recuperação'
  },
  ...(showReclassification
    ? [
        {
          id: RECLASSIFICATION_GRADE_KEY,
          numeric: false,
          disablePadding: false,
          label: 'Reclassificação'
        }
      ]
    : []),
  ...(shouldShowExFinal
    ? [
        {
          id: EX_FINAL_GRADE_KEY,
          numeric: false,
          disablePadding: false,
          label: 'EXFINAL'
        }
      ]
    : []),
  {
    id: 'final_grade',
    numeric: false,
    disablePadding: false,
    label: 'Nota Final',
    last: true
  }
]

export { getHeadCells, EX_FINAL_GRADE_KEY, RECLASSIFICATION_GRADE_KEY }
